import { Injectable, signal, computed, WritableSignal } from '@angular/core';
import { environment } from '../../../environments/environment';

interface AppState {
  email: string;
  organization: string;
  organizations: string[];
  accesos: string[];
}

const emptyState: AppState = {
  email: "",
  organization: "",
  organizations: [],
  accesos: []
};

const accesKeys = {
  ADMIN: 'USER-ADMIN',
  SUPER_ADMIN: 'SUPER-ADMIN'
};

const LOCAL_STORAGE_KEY = 'appState';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private appState: WritableSignal<AppState> = signal(this.loadState());

  isSoporte = computed(() => environment.usersSoporte.includes(this.appState().email));
  isDesarrollo = computed(() => environment.orgsDesarrollo.includes(this.appState().organization));

  constructor() {}

  private loadState(): AppState {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedState ? JSON.parse(savedState) : emptyState;
  }

  private persistState(state: AppState) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }

  setState(email: string, organization: string, organizations: string[], accesos: string[]) {
    const state: AppState = { email, organization, organizations, accesos };
    this.appState.set(state);
    this.persistState(state);
  }

  getState() {
    return this.appState.asReadonly();
  }

  deleteState() {
    this.appState.set(emptyState);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  isAdmin(): boolean {
    const accesos = this.appState().accesos;
    return accesos.includes(accesKeys.ADMIN) || accesos.includes(accesKeys.SUPER_ADMIN);
  }

  setOrganization(organization: string) {
    this.appState.update(state => {
      const updatedState = { ...state, organization };
      this.persistState(updatedState);
      return updatedState;
    });
  }
}